import React, { useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import DealerLocationCard from "../DealerLocationCard/DealerLocationCard"

const DealerPage = ({ dealerLocations, userLocation, ip, uuid }) => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_PAGES_DEALERS_TITLE} - Austin Energy EV Buyers Guide`;
  });

  let dealers = dealerLocations || [];

  let dealerLocationCards = dealers.map((dealer, i) => {
    return <DealerLocationCard key={i} dealerLocation={dealer} />;
  });

  return (
    <section className="container">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="banner-center">
            <h1><FormattedMessage 
              id="dealersHeading"
              defaultMessage="Dealers"
              description="Dealers Title"
            /></h1>
            <p className="lead"><FormattedMessage 
              id="dealersSubheading"
              defaultMessage="The dealers below work closely with Austin Energy to provide a great EV shopping experience. Contact and visit these dealers with the confidence that they'll help you pick the right vehicle for you."
              description="Dealers Sub Title"
            /></p>
            </div>
          </div>
        </div>
        <div className="row justify-content-between">
          {dealerLocationCards}
        </div>

      <div className="row ">
        <div className="col-sm-12">
          <p className="lead">
            <FormattedMessage 
              id="activate.membership"
              defaultMessage="Activate your"
              description="Activate your membership"
            />
            <a
              href="https://www.formstack.com/forms/?1050890-CvJsRgjvdR"
              target="_blank"
              rel="noopener noreferrer"
              style={{textDecoration: 'underline'}}
            >
              &nbsp;Plug-In EVerywhere membership
            </a>
            .
          </p>
        </div>
      </div>
      </div>
    </section>
  );
};

export default DealerPage;

DealerPage.propTypes = {
  chargingStations: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
