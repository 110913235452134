import React, { useState } from "react";
import PropTypes from "prop-types";

// import Map from "./Map/Map(Deprecated)";
import Map from "./Map/Map";
import MapLegend from "./Map/MapLegend";
import MapSelectedStation from "./Map/MapSelectedStation";
import ChargingStationCard from "./ChargingStationCard/ChargingStationCard.js";
import InputWorkingZipcodeForChargingMap from "../InputComponents/InputZipcode/InputZipcodeForChargingMap";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import ShowHideButton from "../ShowHideButton/ShowHideButton";

import "./LocationMap.scss";
import {FormattedMessage} from 'react-intl';

const ChargingMap = ({
  chargingStations,
  userLocation,
  ignoreSuperchargerStations
}) => {
  const [selectedStationId, setSelectedStationId] = useState(null);
  const selectStation = stationId =>
    setSelectedStationId(parseInt(stationId, 10));
  const deselectStations = () => setSelectedStationId(null);

  let stations = chargingStations || [];

  if (stations.length && ignoreSuperchargerStations) {
    stations = stations.filter(station => {
      return (
        station.ev_level1_evse_num ||
        station.ev_level2_evse_num ||
        station.ev_other_evse
      );
    });
  }

  let chargingStationCards = stations.map((station, i) => {
    return <ChargingStationCard key={i} chargingStation={station} />;
  });

  const body = !userLocation ? (
    <div className="spinner-container">
      <LoadingSpinner />
    </div>
  ) : (
      <>
        <div className="map-container" aria-hidden="true">
          <div className="sidebar-container">
            <MapLegend ignoreSuperchargerStations={ignoreSuperchargerStations} />
            <MapSelectedStation
              stations={chargingStations}
              selectedStationId={selectedStationId}
            />
          </div>
          <Map
            chargingStations={chargingStations}
            userLocation={userLocation}
            ignoreSuperchargerStations={ignoreSuperchargerStations}
            selectedStationId={selectedStationId}
            onHoverOnStation={selectStation}
            onHoverOffStation={deselectStations}
            //Set maxZoom to 0  to not use clustering 
            cluster_options={{ radius: 250, maxZoom: parseInt(process.env.REACT_APP_PAGES_CHARGING_USE_CLUSTER)}}
          />
          <p className="legal-disclaimer">
            Multifamily, fleet, and workplace charging stations may have restricted access.
            Data courtesy of the Alternative Fuel Data Center. Map may not reflect
            latest availability of charging stations.
          </p>
        </div>

        <ShowHideButton buttonText="Full List of Stations">
          <div className="charging-station-cards-container">
            <div className="row">
              <div className="col-sm-6 offset-sm-3">{chargingStationCards}</div>
            </div>
          </div>
        </ShowHideButton>
      </>
    );

  return (
    <section className="container">
      <div className="ChargingMap">
        <div className="row">
          <div className="col-sm-12">
            <h2>
              <FormattedMessage 
                id="chargingStations"
                defaultMessage="Charging Stations"
                description="Charging Stations"
              />
            </h2>
            <InputWorkingZipcodeForChargingMap />
          </div>
        </div>
        {body}
      </div>
      <div className="row">
        <div className="col-sm-12">
          <p className="lead">
            Activate your{" "}
            <a
              href="https://www.formstack.com/forms/?1050890-CvJsRgjvdR"
              target="_blank"
              rel="noopener noreferrer"
            >
              Plug-In EVerywhere membership
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};

export default ChargingMap;

ChargingMap.propTypes = {
  chargingStations: PropTypes.array,
  userLocation: PropTypes.object,
  ignoreSuperchargerStations: PropTypes.bool
};
