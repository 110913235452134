import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import green from "../../assets/images/chargeway/green.png";
import blue from "../../assets/images/chargeway/blue.png";
import red from "../../assets/images/chargeway/red.png";

import { FormattedMessage, useIntl } from "react-intl";

const createId = string =>
  string
    .toLowerCase()
    .split(" ")
    .join("_")
    .replace("?", "");

const QuestionLink = ({ q }) => {
  return (
    <li>
      <a className="d-block py-1" href={`#` + createId(q)}>
        {q}
      </a>
    </li>
  );
};

const RenderQuestion = ({ q, children }) => {
  return (
    <div>
      <h3 className="pt-4" id={createId(q)}>
        {q}
      </h3>
      {children}
    </div>
  );
};

const FAQ = ({ ip, uuid }) => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_PAGES_FAQ_TITLE} - Austin Energy EV Buyers Guide`;
  });

  const intl = useIntl()
  const faqContent24 = intl.formatMessage ? intl.formatMessage({ id: "faqContent24", defaultMessage: "What is an Electric Vehicle?"}) : "What is an Electric Vehicle?";
  const faqContent25 = intl.formatMessage ? intl.formatMessage({ id: "faqContent25", defaultMessage: "Why should I drive an EV?"}) : "Why should I drive an EV?";
  const faqContent26 = intl.formatMessage ? intl.formatMessage({ id: "faqContent26", defaultMessage: "What should I consider before purchasing an Electric Vehicle?"}) : "What should I consider before purchasing an Electric Vehicle?";
  const faqContent27 = intl.formatMessage ? intl.formatMessage({ id: "faqContent27", defaultMessage: "How fast are EVs?"}) : "How fast are EVs?";
  const faqContent28 = intl.formatMessage ? intl.formatMessage({ id: "faqContent28", defaultMessage: "What maintenance do electric vehicles require?"}) : "What maintenance do electric vehicles require?";
  const faqContent29 = intl.formatMessage ? intl.formatMessage({ id: "faqContent29", defaultMessage: "Are EV batteries better than the use of gasoline in cars?"}) : "Are EV batteries better than the use of gasoline in cars?";
  const faqContent30 = intl.formatMessage ? intl.formatMessage({ id: "faqContent30", defaultMessage: "Are EVs actually better for the environment?"}) : "Are EVs actually better for the environment?";
  const faqContent31 = intl.formatMessage ? intl.formatMessage({ id: "faqContent31", defaultMessage: "Why should I drive an EV?"}) : "Why should I drive an EV?";
  // const faqContent32 = intl.formatMessage ? intl.formatMessage({ id: "faqContent32", defaultMessage: "What is an ICE car?"}) : "What is an ICE car?";
  const faqContent34 = intl.formatMessage ? intl.formatMessage({ id: "faqContent34", defaultMessage: "Where do I charge my EV?"}) : "Where do I charge my EV?";
  const faqContent35 = intl.formatMessage ? intl.formatMessage({ id: "faqContent35", defaultMessage: "What are the different ways to charge your EV?"}) : "What are the different ways to charge your EV?";
  const faqContent36 = intl.formatMessage ? intl.formatMessage({ id: "faqContent36", defaultMessage: "How long does charging take?"}) : "How long does charging take?";
  const faqContent37 = intl.formatMessage ? intl.formatMessage({ id: "faqContent37", defaultMessage: "Do I need to buy a home charging station? If so, which one?"}) : "Do I need to buy a home charging station? If so, which one?";
  const faqContent38 = intl.formatMessage ? intl.formatMessage({ id: "faqContent38", defaultMessage: "Wouldn't that increase the electric bill?"}) : "Wouldn't that increase the electric bill?";
  const faqContent39 = intl.formatMessage ? intl.formatMessage({ id: "faqContent39", defaultMessage: "Does it take a long time to charge?"}) : "Does it take a long time to charge?";
  const faqContent40 = intl.formatMessage ? intl.formatMessage({ id: "faqContent30", defaultMessage: "How would I charge my electric car on the go?"}) : "How would I charge my electric car on the go?";
  const faqHere = intl.formatMessage ? intl.formatMessage({ id: "faqHere", defaultMessage: "here"}) : "here";
  const faq1 = intl.formatMessage ? intl.formatMessage({ id: "faq1", defaultMessage: "What is an Electric Vehicle?"}) : "What is an Electric Vehicle?";
  const faq1Link = intl.formatMessage ? intl.formatMessage({ id: "faq1A-link", defaultMessage: "What types of electric vehicles are available?"}) : "What types of electric vehicles are available?";
  const faq2 = intl.formatMessage ? intl.formatMessage({ id: "faq2", defaultMessage: "Why should I drive an EV?"}) : "Why should I drive an EV?";
  const faqTotalRange = intl.formatMessage ? intl.formatMessage({ id: "faqTotalRange", defaultMessage: "Total Range:"}) : "Total Range:";
  const faqCharging = intl.formatMessage ? intl.formatMessage({ id: "faqCharging", defaultMessage: "Charging:"}) : "Charging:";
  const faqSavings = intl.formatMessage ? intl.formatMessage({ id: "faqSavings", defaultMessage: "Savings:"}) : "Savings:";

  return (
      <section className="container" aria-label="Frequently Asked Questions Page">
        <div className="row">
          <div className="col text-center">
            <h1>{process.env.REACT_APP_FULL_COMPANY_NAME} EV FAQ</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 d-none d-md-block">
            <div className="sticky mt-3">
              <div>
                <p className="h2">
                  <a href="#vehicles"><FormattedMessage
                    id="faqContent23"
                    defaultMessage="Vehicles"
                    description="Vehicles"
                  /></a>
                </p>
                <ul>
                  <QuestionLink q={faqContent24} />
                  <QuestionLink q={faqContent25} />
                  <QuestionLink q={faqContent26} />
                  <QuestionLink q={faqContent27} />
                  <QuestionLink q={faqContent28} />
                  <QuestionLink q={faqContent29} />
                  <QuestionLink q={faqContent30} />
                  <QuestionLink q={faqContent31} />
                  {/* <QuestionLink q={faqContent32} /> */}
                </ul>
                <br />
                <p className="h2">
                  <a href="#charging"><FormattedMessage
                    id="faqContent33"
                    defaultMessage="Charging"
                    description="Charging"
                  /></a>
                </p>
                <ul>
                  <QuestionLink q={faqContent34} />
                  <QuestionLink q={faqContent35} />
                  <QuestionLink q={faqContent36} />
                  <QuestionLink q={faqContent37} />
                  <QuestionLink q={faqContent38} />
                  <QuestionLink q={faqContent39} />
                  <QuestionLink q={faqContent40} />
                </ul>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="SimpleCopyPageBody">
              <h2 id="vehicles" className="mt-3">
                  <FormattedMessage
                    id="faqVehicles"
                    defaultMessage="Vehicles"
                  />
              </h2>
              <RenderQuestion q={faq1}>
                <p className="mb-0">
                  <FormattedMessage
                    id="faq1AA"
                    defaultMessage="Un vehículo eléctrico es cualquier vehículo que pueda circular con electricidad. Si puede enchufarlo para cargarlo, es un vehículo eléctrico. Un vehículo totalmente eléctrico (a veces llamado vehículo eléctrico de batería o (BEV) funciona únicamente con electricidad y no utiliza gasolina. {link} Existen dos tipos de vehículos eléctricos:{br}"
                    values={{
                      link: <Link to="/vehicles" style={{textDecoration: 'underline'}}>{faq1Link}</Link>,
                      br: <br/>
                    }}
                  />
                </p>
                <ul>
                  <li>
                    <FormattedMessage
                      id="faq1A-1"
                      defaultMessage="Battery Electric Vehicle (BEV) - relies exclusively on a battery to power the car. Say goodbye to the gas pump!"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="faq1A-2"
                      defaultMessage="Plug-In Hybrid Electric Vehicles (PHEV) - uses both electricity and gasoline. When driving, they use battery power and switch to gasoline when the battery is empty."
                    />
                  </li>
                </ul>
              </RenderQuestion>

              <RenderQuestion q={faq2}>
                <p>
                  <FormattedMessage
                    id="faq2A"
                    defaultMessage="Easy to use, easy to maintain and easy to charge, electric vehicles are fun to drive and can save you money while helping the environment. EVs are cheaper, faster, and cleaner to operate plus they have much lower maintenance costs. If you are like most people commuting about 30 miles a day, then an EV can mean more money in your bank account and more time to enjoy your life."
                  />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent26}>
                <p className="mb-0">
                  <FormattedMessage
                    id="faq3AA"
                    defaultMessage="Similar to selecting a gasoline-powered car, choosing the electric vehicle that’s best for you depends on a number of different factors including your driving habits and personal preference. Here are some EV-specific factors to consider:"
                  />
                </p>
                <ul className="mt-0">
                  <li>
                    <FormattedMessage
                      id="faq3A-1"
                      defaultMessage="{totalRange} How far will you travel? The total range of current electric vehicles vary greatly—anywhere from 40-350 miles."
                      values={{
                        totalRange: <strong>{faqTotalRange}</strong>
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="faq3A-2"
                      defaultMessage="{charging} ¿Dónde va a cargar? A dónde conduce y la forma en la que cargará su vehículo pueden ayudarle a decidir qué vehículo eléctrico se ajustará a sus necesidades."
                      values={{
                        charging: <strong>{faqCharging}</strong>
                      }}
                    />
                  </li>

                  <li>
                    <FormattedMessage
                      id="faq3A-3"
                      defaultMessage="{savings} ¿Con qué frecuencia requiere mantenimiento su automóvil de gasolina? ¿Con qué frecuencia llena el depósito de gasolina? Los conductores de vehículos eléctricos ahorran en combustible y en el mantenimiento del automóvil, lo que también ahorra tiempo y ayuda al medio ambiente."
                      values={{
                        savings: <strong>{faqSavings}</strong>
                      }}
                    />
                  </li>
                </ul>
                <p>
                    <FormattedMessage
                      id="faq3A-4"
                      defaultMessage="If your daily commute is less than 40 miles, virtually every electric vehicle will be able to handle your daily driving without the need for fuel. If you want the ability to drive much farther, several all-electric vehicles can travel 100 to 300+ miles on a charge. For the rare road trip, the drive can be done stopping at charging stations on the way to your destination."
                    />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent27}>
                <p>
                  <FormattedMessage
                    id="faq4A"
                    defaultMessage="Fast! When you hit the accelerator EVs GO! EVs provide instant power (torque), providing faster acceleration than any gas-powered car you have had before, making EVs very fun to drive. With an electric motor, the driving experience is also quieter - and often more enjoyable - than a traditional gasoline-fueled car."
                  />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent28}>
                <FormattedMessage
                    id="faq5AA"
                    defaultMessage="With an EV, there are no oil changes, no spark plugs, no timing belts. No more expensive fixes over time.{br} Since there are significantly fewer moving parts in an EV compared to a traditional vehicle, less ongoing preventative maintenance is needed (just tire rotations). Since there's no exhaust, there's also no muffler or catalytic converter. That means more money in your pocket.{br} Brakes last longer on EVs too. When you take your foot off of the accelerator the electric motor slows the car down. It even uses the extra energy and stores it back in the battery giving your EV a little more range.{br} All automakers also offer at least 8-year warranties on the batteries, if not longer."
                    values={{
                      br: <><br/><br/></>
                    }}
                  />
              </RenderQuestion>

              <RenderQuestion q={faqContent29}>
                <p>
                  <FormattedMessage
                    id="faq6A"
                    defaultMessage="In many ways, yes. Unlike gasoline, EV batteries do not emit harmful carbon emissions into the atmosphere to pollute air and water. Also, once the batteries cannot be used for cars anymore, they can be reused for energy storage. Some car companies are already using blocks of old EV batteries to power buildings. At the end of their life, they are also recyclable."
                  />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent30}>
                <p>
                  <FormattedMessage
                    id="faq7A"
                    defaultMessage="Electric vehicles produce zero exhaust – no nasty chemicals that contribute to smog or other forms of air or water pollution. Switching cars to electric will dramatically improve local air quality, save millions in health costs from respiratory diseases, and reduce the long-term effects of climate change."
                  />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent31}>
                <p>
                  <FormattedMessage
                    id="faq8A"
                    defaultMessage="You may hear or see the term “ICE” when referring to a gas-powered vehicle. “ICE” stands for Internal Combustion Engine. In addition to contributing to harmful greenhouse gases, these cars require more maintenance and have more moving parts than electric vehicles."
                  />
                </p>
              </RenderQuestion>

              <h2 className="mt-5" id="charging">
                  <FormattedMessage
                    id="faqCharging"
                    defaultMessage="Charging"
                  />
              </h2>

              <RenderQuestion q={faqContent34}>
                <p>
                  <FormattedMessage
                    id="faq9A-1"
                    defaultMessage="Do you have a house? If you have a garage or driveway, you can charge at home, so you can leave every morning with a “full tank”. With the range of most EVs, you don't even need to charge every night! You can say goodbye to the gas pump and stop going to the gas station during the week, which saves you time and money. And unlike gasoline that needs a dedicated station, “electric fuel” is available in many more places. Your home, hotels, movie theatres, malls, grocery stores, vacation resorts, and more! You can even plug into a regular outlet.{br} Even when you are not home, you can also charge at hundreds of charging ports available to the public, both in Austin and beyond the city.{br} Finding charging stations is easy. Simply download the Chargeway app, choose your EV and all of the charging stations for your car automatically appear."
                    values={{
                      br: <><br/><br/></>,
                      link: <a
                      href="http://www.chargeway.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{textDecoration: 'underline'}}
                    >
                      Chargeway app
                    </a>
                    }}
                  />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent35}>
                <p>
                  <FormattedMessage
                    id="faq10A"
                    defaultMessage="Similar to gas cars that can use “Regular” or “Premium”, EVs can use different plug types. With the Chargeway app your EVs plug(s) are color coded to stations you can use for your selected vehicle. There are three plug colors:"
                  />
                </p>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="text-center px-5 mb-2">
                      <img className="img-fluid" alt="green" src={green} />
                    </div>
                    <ul>
                      <li>
                        <FormattedMessage
                          id="faqContent1-1"
                          defaultMessage="For Chevrolet, BMW, VW and other US / European brands"
                        />
                      </li>
                      <li><FormattedMessage
                          id="faqContent1-2"
                          defaultMessage="Both Standard and Fast Charging"
                        /></li>
                      <li><FormattedMessage
                          id="faqContent1-3"
                          defaultMessage="Often referred to as J1772 and CCS Combo"
                        /></li>
                    </ul>
                  </div>
                  <div className="col-sm-4">
                    <div className="text-center px-5 mb-2">
                      <img className="img-fluid" alt="blue" src={blue} />
                    </div>
                    <ul>
                      <li>
                      <FormattedMessage
                          id="faqContent2-1"
                          defaultMessage="For Nissan, Mitsubishi, Kia and some Asia market brands"
                        />
                      </li>
                      <li><FormattedMessage
                          id="faqContent2-2"
                          defaultMessage="Fast Charging ONLY"
                        /></li>
                      <li><FormattedMessage
                          id="faqContent2-3"
                          defaultMessage="Often referred to 'CHAdeMO'"
                        /></li>
                    </ul>
                  </div>
                  <div className="col-sm-4">
                    <div className="text-center px-5 mb-2">
                      <img className="img-fluid" alt="red" src={red} />
                    </div>
                    <ul>
                      <li><FormattedMessage
                          id="faqContent3-1"
                          defaultMessage="For Tesla vehicles ONLY"
                        /></li>
                      <li><FormattedMessage
                          id="faqContent3-2"
                          defaultMessage="Both Standard and Fast Charging"
                        /></li>
                      <li><FormattedMessage
                          id="faqContent3-3"
                          defaultMessage="Often referred to as 'Destination Chargers' and 'Superchargers'"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </RenderQuestion>

              <RenderQuestion q={faqContent36}>
                <p>
                  <FormattedMessage
                    id="faq11A"
                    defaultMessage="There are seven different power levels for charging including both Standard and Fast Charging. Some EVs can only use standard charging, while others can use standard as well as fast charging."
                  />
                </p>
                <p className="lead"><FormattedMessage
                    id="stdCharging"
                    defaultMessage="Standard Charging:"
                  /></p>
                <p><FormattedMessage
                    id="faqContent4-1"
                    defaultMessage="Level 1"
                  /></p>
                <ul>
                  <li>
                    <FormattedMessage
                      id="faqContent4-2"
                      defaultMessage="This level uses the charging cable provided with every EV"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="faqContent4-3"
                      defaultMessage="Provides 3-5 miles per hour of charge"
                    />
                  </li>
                </ul>
                <p><FormattedMessage
                      id="faqContent4-4"
                      defaultMessage="Level 2"
                    /></p>
                <ul>
                  <li>
                    <FormattedMessage
                      id="faqContent4-5"
                      defaultMessage="This level offers faster charging at home and is appropriate for workplace charging"
                    />
                  </li>
                  <li><FormattedMessage
                      id="faqContent4-6"
                      defaultMessage="Provides 20 to 60 miles per hour of charge"
                    /></li>
                </ul>
                <p className="lead"><FormattedMessage
                      id="faqFastCharge"
                      defaultMessage="Fast Charging:"
                    /></p>
                <p>
                    <FormattedMessage
                      id="faqContent5AA"
                      defaultMessage="EVs capable of fast charging can use levels 3 through 7. Some EVs can faster charge at higher levels than other EVs. Download the Chargeway app to see which stations your vehicle can use and how fast you can charge. Typical fast charging speeds are:{br}Level 3: Provides 80-100 miles per hour of charge{br}Level 4: Provides 101-175 miles per hour of charge{br}Level 5: Provides 200-350 miles per hour of charge{br}Level 6: Provides 400-700 miles per hour of charge{br}Level 7: Provides 1200-1400 miles per hour of charge{br}Fast charging is available for $0.21 per minute, conveniently located near major transit routes for the times you need to charge up and go. If you want to use a public charging port and plan to be away from your vehicle for more than 30 minutes, use a level 2 charger instead of a fast charger."
                      values={{
                        br: <><br/><br/></>,
                        link: <a
                        href="http://www.chargeway.net"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{textDecoration: 'underline'}}
                      >
                        Chargeway app
                      </a>
                      }}
                    />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent37}>
                <p>
                  <FormattedMessage
                    id="faqContent7"
                    defaultMessage="New plug-in vehicles come with Green 1 connectors that plug into standard 120-volt household outlets. So, you do not need to buy a home charging station as long as you have access to a standard outlet. However, for faster charging, many drivers buy a Green 2 charging station to plug into a 240-volt outlet. All Green 2 charging stations come with the standard Green connector that allow any plug-in vehicle to connect.{br} Tesla vehicles comes with a Red 1 connector. A Tesla high-powered wall connector (Red 2) can be purchased through Tesla. Tesla owners can also use the standard charging Green adapter to connect to any standard level Green station."
                    values={{
                      br: <><br/><br/></>
                    }}
                  />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent38}>
                <p>
                  <FormattedMessage
                    id="faqContent9C"
                    defaultMessage=""
                    values={{
                      link: <Link to="/" style={{textDecoration: 'underline'}}>{faqHere}</Link>
                    }}
                  />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent39}>
                <p>
                  <FormattedMessage
                    id="faqContent11B"
                    defaultMessage=""
                    values={{
                      link: <a
                      href="http://www.chargeway.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{textDecoration: 'underline'}}
                    >
                      Chargeway app
                    </a>
                    }}
                  />
                </p>
              </RenderQuestion>

              <RenderQuestion q={faqContent40}>
                <p>
                  <FormattedMessage
                    id="faqContent13"
                    defaultMessage=""
                  />
                </p>
              </RenderQuestion>

          
            </div>
          </div>
        </div>
      </section>
  );
};

export default FAQ;

FAQ.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string
};
