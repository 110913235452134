import React from "react";
import PropTypes from "prop-types";

import "./LocationMap.scss";
import {FormattedMessage} from 'react-intl';

const ChargingMap = () => {

  return (
    <section className="container">
      <div className="ChargingMap">
        <div className="row">
          <div className="col-sm-12">
            <h2>
              <FormattedMessage 
                id="chargingStations"
                defaultMessage="Charging Stations"
                description="Charging Stations"
              />
            </h2>
          </div>
        </div>
        {/* {body} */}
        <iframe title="chargeway" border="0" src="https://embed.chargeway.net/?darklogo=y&zipcode=78702"/>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <p className="lead">
            <FormattedMessage 
              id="activate.membership"
              defaultMessage="Activate your"
              description="Activate your membership"
            />&nbsp;
            <a
              href="https://www.formstack.com/forms/?1050890-CvJsRgjvdR"
              target="_blank"
              rel="noopener noreferrer"
              style={{textDecoration: 'underline'}}
            >
              Plug-In EVerywhere membership
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};

export default ChargingMap;

ChargingMap.propTypes = {
  chargingStations: PropTypes.array,
  userLocation: PropTypes.object,
  ignoreSuperchargerStations: PropTypes.bool
};
